.shoutout-video-group,
.banner-video-group {
  &:not(:last-child) {
    ul.video-shoutout-list,
    ul.video-banner-list {
      border-bottom: 1px solid #ccc;
      margin-bottom: 12px;
    }
  }
}

.banner-video-group {
  .banner-occurrences-list {
    max-height: 450px;
    overflow: scroll;
    margin: 0 0 0 80px;
  }
}

.triangle {
  height: 0px;
  width: 0px;
  border-style: solid;
  position: relative;
  left: -4px;
}

.triangle-red {
  border-color: #f44336 transparent;
  border-width: 6px 6px 0px 6px;
  top: 10px;
}

.triangle-green {
  border-color: #4caf50 transparent;
  border-width: 0px 6px 6px 6px;
  top: -10px;
}

.shoutout-highlight {
  font-weight: bold;
}

.campaign-date-filter {
  .MuiInputBase-formControl {
    height: 36px;
    width: 180px;
  }
}

.video-delete-container {
  display: flex;
  float: right;
  height: 100%;
  width: 40px;
  position: absolute;
  right: 0;
  top: 0;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  .campaign-video-delete {
    padding: 3px;
    height: 35px;
    color: red;
    border: 2px solid red;
    border-radius: 5px;
    background: white;
    cursor: pointer;
  }

  .campaign-video-chart {
    padding: 3px;
    height: 35px;
    color: #0048f2;
    border: 2px solid #0048f2;
    border-radius: 5px;
    background: white;
    margin-bottom: 16px;
    cursor: pointer;
  }
}

.campaign-video-grid {
  position: relative;
  padding-right: 40px;

  &:hover {
    .campaign-video-delete,
    .campaign-video-chart {
      display: block;
    }
  }
}

.comment-delete-container {
  position: absolute;
  bottom: 8px;
  right: 4px;

  .comment-video-delete {
    color: red;
    cursor: pointer;
    font-size: 10px;
    display: none;
  }
}
