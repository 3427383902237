.comment-li {
  .comment-open {
    display: none;
  }

  &:hover {
    .comment-video-delete {
      display: inline;
    }

    .comment-open {
      display: inline;
    }
  }

  .comment-meta-container {
    margin-top: 8px;
  }

  .comment-meta {
    font-size: 14px;

    i.fa,
    svg {
      margin-right: 4px;
    }
  }

  .comment-body {
    padding-left: 48px;
    margin-top: -42px;
    position: relative;

    .comment-top-body .MuiGrid-item {
      margin-right: 4px;
    }
  }

  hr {
    margin: 12px 0px;
  }
}
