.influencers-table,
.watchlist-table {
  .influencer-size {
    line-height: 12px;
    padding: 2px 4px;
    font-size: 0.6rem;
  }

  .watchlist-remove {
    color: red;
    cursor: pointer;
    font-size: 10px;
    position: absolute;
    display: none;
    left: 0;
    bottom: -14px;
  }

  tr:hover {
    background: #f5f5f5;

    .watchlist-remove {
      display: inline-block !important;
    }
  }

  p.hover-row {
    font-size: 1rem;
    text-overflow: ellipsis;
    overflow-x: hidden;
    white-space: nowrap;
    width: 160px;
  }

  .influencer-name-avatar {
    .influencer-name {
      margin-left: 48px;
      position: relative;
    }

    .MuiAvatar-root {
      float: left;
      margin-right: 8px;
    }
  }
}
