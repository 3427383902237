/* *::-webkit-scrollbar {
  width: 0;
  height: 0;
} */

.text-ellipsis--2 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: var(--lines);
  -webkit-box-orient: vertical;
  white-space: normal;
}

.show-on-hover-root .show-on-hover {
  display: none;
}

.show-on-hover-root:hover .show-on-hover {
  display: block;
}

.show-on-hover-root .padding-on-hover {
  padding-left: 0;
  transition: all 0.2s ease-out;
}

.show-on-hover-root:hover .padding-on-hover {
  padding-left: 20px;
}

.show-on-hover-root .slide-on-hover {
  display: none;
}

.show-on-hover-root:hover .slide-on-hover {
  animation: slideIn 0.2s ease-out;
  display: block;
}

@keyframes slideIn {
  from {
    left: -80px;
  }

  to {
    left: -32px;
  }
}

.row-hover-color:hover {
  background: #f9f9f9;
}
