.box {
  position: relative;
}
.ribbon {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 35px;
  height: 35px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: #79a70a;
  background: linear-gradient(#0048f2 0%, #0048f2 100%);
  position: absolute;
  top: 19px;
  left: -21px;
}
// .ribbon span::before {
//   content: "";
//   position: absolute; left: 0px; top: 100%;
//   z-index: -1;
//   border-left: 3px solid #0048F2;
//   border-right: 3px solid transparent;
//   border-bottom: 3px solid transparent;
//   border-top: 3px solid #0048F2;
// }
// .ribbon span::after {
//   content: "";
//   position: absolute; right: 0px; top: 100%;
//   z-index: -1;
//   border-left: 3px solid transparent;
//   border-right: 3px solid #0048F2;
//   border-bottom: 3px solid transparent;
//   border-top: 3px solid #0048F2;
// }

.influencers-table {
  .ribbon {
    left: -17px;
    top: -9px;
    width: 42px;
    height: 42px;
  }

  .ribbon span {
    line-height: 13px;
    height: 13px;
    width: 80px;
    top: 9px;
    left: -26px;
  }
}

.influencer-card {
  .ribbon {
    left: 0px;
    top: 0px;
    width: 60px;
    height: 60px;
  }

  .ribbon span {
    top: 11px;
    left: -26px;
  }
}

.influencer-profile-card {
  .ribbon {
    left: 0px;
    top: 0px;
    width: 60px;
    height: 60px;
  }

  .ribbon span {
    top: 11px;
    left: -32px;
  }
}

.comment-li {
  .ribbon {
    left: -3px;
    top: -13px;
    width: 55px;
    height: 55px;
  }

  .ribbon span {
    top: 7px;
    left: -37px;
    line-height: 12px;
    height: 12px;
    font-size: 9px;
  }
}

.app-drawer-list {
  .ribbon-beta {
    position: relative;
    background: #79a70a;
    background: linear-gradient(#0048f2 0%, #0048f2 100%);
    font-size: 10px;
    color: white;
    padding: 2px 8px;
    text-align: center;
    font-weight: bold;
  }
}

.ribbon-mention {
  position: relative;
  background: linear-gradient(#222 0%, #111 100%);
  font-size: 10px;
  color: white;
  padding: 2px 8px;
  text-align: center;
  font-weight: bold;
  display: inline-block;

  &.m-positive {
    background: linear-gradient(#85ce88 0%, #49b54d 100%);
  }

  &.m-negative {
    background: linear-gradient(#ed675e 0%, #e83c30 100%);
  }

  &.m-emv {
    background: linear-gradient(#4bd623 0%, #379c19 100%);
  }

  &.m-views {
    background: linear-gradient(#31afe5 0%, #188abb 100%);
  }

  &.m-comments {
    background: linear-gradient(#c86ee7 0%, #ac25db 100%);
  }

  &.m-engagement {
    background: linear-gradient(#f9a300 0%, #a46b00 100%);
  }

  &.m-mentions {
    background: linear-gradient(#d287eb 0%, #b73edf 100%);
  }
}
