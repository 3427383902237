.sentiment-list-dot {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 8px;
}

.list-item-left {
  font-size: 16px;
}

.list-item-right {
  font-size: 18px;
  font-weight: 700;
  margin-left: 6px;
}

.sentiment-p {
  background-color: #85ce88;
}

.sentiment-sp {
  background-color: #a1d9a3;
}

.sentiment-n {
  background-color: #f2938e;
}

.sentiment-sn {
  background-color: #ed675e;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.form-filters {
  margin-top: 16px;
}

.top-title {
  font-weight: 300;
  font-size: 1.8rem;
  margin-top: 10px;
  color: #333;
}

.influencer-box-link {
  display: block;
}

.influencer-box-link:hover {
  border: solid 1px #0048f2;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.influencer-avatar-holder {
  border-radius: 100px;
  border: solid 4px #0048f2;
  float: left;
  width: 150px;
  height: 150px;
}

.influencer-avatar-holder img {
  width: 100%;
  height: auto;
  border-radius: 100px;
}

.influencers-list .influencer-avatar-holder {
  border-radius: 100px;
  border: solid 4px #0048f2;
  float: none;
  width: 120px;
  height: 120px;
  margin: 0 auto;
}

influencers-list .influencer-avatar-holder {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  float: none;
}

.influencer-bio {
  margin-left: 180px;
}

.influencer-bio p {
  line-height: 1;
  margin: 0;
  padding: 0;
}

.influencer-type {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  background-color: #4f84ff;
  display: inline;
  padding: 4px 8px !important;
}

.influencers-list .influencer-bio {
  margin-left: 0;
  margin-top: 10px;
}

.influencer-score {
  color: #4f84ff;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 0;
  line-height: 1;
  margin-top: 36px;
}

.influencer-score.secondary-color {
  color: #759eff;
}

.influencer-score-text {
  color: #999;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
}

.padding-tb-20 {
  padding: 20px 0;
}

.progress-percentage {
  right: 0;
  top: 4px;
}

.mt-16 {
  margin-top: 16px;
}

.list-group-item {
  border-left: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-right: none;
  padding-left: 0;
  padding-right: 0;
}

.list-group-item:first-child {
  border-top: none;
}

.list-group-item:hover {
  background: none;
}

.influencer-description {
  font-size: 15px;
  color: #333;
  padding-top: 20px !important;
  line-height: 1.2 !important;
  max-width: 600px;
}

.profile-desc-list {
  list-style: none;
  padding: 0;
  margin-top: 18px;
}

.profile-desc-list li {
  display: inline-block;
  margin-right: 40px;
}

.list-title {
  font-size: 12px;
  font-family: 'Montserrat';
  font-weight: 400;
  color: #888;
  margin: 0;
  padding: 0;
}

.list-content {
  font-size: 18px;
  color: #333;
  font-weight: 600;
  padding: 8px 0 0 0 !important;
  margin: 0;
}

.influencer-score-col .influencer-score {
  font-size: 52px;
  margin-top: 0;
}

.influencer-score-col .influencer-score.secondary-color {
  font-size: 42px;
}

.influencer-score-col .influencer-score-text {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
}

.fa-info-circle {
  font-family: fontawesome;
  font-style: normal;
  color: #999;
  margin-left: 6px;
}

.filters-bar {
  background: #f4f6f8;
  position: fixed;
  left: 0;
  top: 66px;
  left: 280px;
  right: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
  z-index: 1;
  padding-bottom: 4px;
}

.filters-bar .nav-left {
  float: left;
  margin-left: 20px;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  position: relative;
}

.filters-bar .nav-right {
  float: right;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  position: relative;
  margin-right: 20px;
}

.main-content {
  padding-top: 152px;
}

h2 {
  position: relative;
  font-size: 1.6rem;
  margin-top: 8px;
  display: inline-block;
}

.is-collapsed .filters-bar {
  left: 70px;
}

@media screen and (max-width: 1439px) and (min-width: 992px) {
  .filters-bar {
    left: 70px;
  }
  .is-collapsed .filters-bar {
    left: 280px;
  }
}

@media screen and (max-width: 991px) {
  .filters-bar {
    left: 0px;
  }
  .filters-bar .nav-left {
    margin-left: 4px;
  }
}

.sidebar:hover .logo-text {
  display: block !important;
}

.filters-bar .nav-right .btn {
  margin-top: 14px;
  margin-left: 12px;
}

.btn i {
  margin-right: 8px;
}

.btn {
  padding: 0.475rem 0.75rem;
}

.btn-primary:hover,
.btn-outline-primary:hover {
  background-color: #0044e1;
  border-color: #0044e1;
}

.btn-primary {
  background-color: #0048f2;
  border-color: #0048f2;
}

.btn-outline-primary {
  background-color: #fbfbfc;
  border-color: #0048f2;
  color: #0048f2;
}

.ia {
  font-family: 'FontAwesome';
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn .fa-sliders {
  font-size: 20px;
}

.btn .fa-search {
  font-size: 18px;
}

*:focus {
  outline: none !important;
}

.btn:focus {
  outline: 0 !important;
}

.btn {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.search-option-container {
  position: relative;
  top: -24px;
}

.header .header-container .nav-right > li > a {
  padding-right: 20px;
}

.header .header-container .nav-left {
  margin-left: 0;
}

.search-option-container .card-body {
  background: #f9fbfc;
}

.filtering-options {
  padding-bottom: 32px;
}

.filtering-options:last-child {
  padding-bottom: 0;
}

.option-section {
  position: relative;
  margin-bottom: 8px;
  z-index: 0;
}

.option-section p {
  color: #545454;
  font-size: 13px;
  position: relative;
  background: #f9fbfc;
  display: inline;
  padding-right: 16px;
  z-index: 1;
}

.option-section:before {
  content: '';
  height: 1px;
  position: absolute;
  background: #ddd;
  top: 12px;
  left: 0;
  right: 0;
  z-index: 0;
}

.option-content {
  float: left;
  margin-right: 48px;
}

.option-content:last-child {
  margin-right: 0;
}

.option-list {
  list-style: none;
  padding: 0;
}

.option-list li {
  display: inline-block;
}

.option-title {
  color: #333;
  font-size: 15px;
  font-weight: 600;
  padding: 8px 0;
  margin: 0;
  line-height: 1.9;
}

.option-selector {
  border: solid 1px #ccc;
  color: #333;
  padding: 8px 12px;
  font-size: 16px;
  background: #fafafa;
  margin-right: 8px;
  border-radius: 4px;
  display: block;
}

.option-selected {
  border: solid 1px #0048f2;
  background: #d7e2ff;
}

.option-sort .option-selector {
  margin: 0;
}

.option-sort li:first-child .option-selector {
  border-right: none;
  border-radius: 4px 0 0 4px;
}

.option-sort li:last-child .option-selector {
  border-radius: 0 4px 4px 0;
}

.selected-sorting .option-selector {
  border-color: #0048f2;
}

.header .header-container .nav-left > li > a {
  padding: 0 20px;
}

.back-button {
  margin-right: 12px;
  position: relative;
  top: -2px;
  color: #333;
  font-size: 20px;
}

.link-button {
  color: #0048f2 !important;
  font-size: 20px;
  padding: 0 10px;
}

.option-list .form-control {
  height: auto;
  padding: 0.6rem 0.75rem;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
  font-size: 16px;
  max-height: 42px;
}

.w-280 {
  width: 280px;
}

.button-clear {
  padding: 10px 0;
  top: 8px;
  position: relative;
}

.main-form-content .gap-20 {
  overflow: auto !important;
}

.main-form-content .option-section p {
  background: #fff;
}

.main-form-content .dropdown-toggle::after {
  display: none;
}

.removable-option .option-selector {
  padding-right: 40px;
  position: relative;
}

.remove-option {
  border-radius: 50%;
  border: solid 1px #ccc;
  position: absolute;
  right: 8px;
  top: 10px;
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  background: #fff;
}

.remove-option .ti-close {
  color: #777;
  font-size: 9px;
  position: relative;
  top: -4px;
}

.remove-option:hover {
  border-color: #0048f2;
}

.remove-option:hover .ti-close {
  color: #0048f2;
}

.option-add {
  position: relative;
  top: -1px;
  padding: 0.6rem 0.9rem;
}

.option-add .fa-plus {
  margin: 0;
  font-size: 18px;
  position: relative;
  top: 2px;
}

.dropdown-form {
  padding: 10px 20px;
  width: 423px;
}

.dropdown-form.select-form {
  width: auto;
}

.dropdown-form label {
  font-weight: 700;
  width: 100%;
}

.dropdownmenu-input {
  float: left;
  width: 320px;
}

.dropdown-form .option-add {
  margin-left: 4px;
  float: left;
}

.empty-filter-message {
  margin-left: 12px;
  font-size: 16px;
  font-style: italic;
  color: #999;
}

.select-form select {
  margin-bottom: 16px;
}

.select-form .option-add {
  margin: 0;
}

.sidebar-menu li a.active {
  color: #333;
}

.main-form-content .gap-20 {
  overflow: visible !important;
}

.button-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #069;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
