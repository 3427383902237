.influencer-page .influencer-platform-select {
  display: inline-block;
  float: right;
}

.influencer-platform-select {
  button {
    margin-left: 12px;
    color: #333;
    background: none;
    border: none;

    filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
  }

  button:hover,
  button.active-platform {
    filter: none;
    -webkit-filter: grayscale(0%);
  }
}
